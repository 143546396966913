import { computed, ref, watch } from '@vue/composition-api';
import store from '@/store';
import { useRouter } from '@core/utils/utils';
import smartDeviceStoreModule from '../smartDeviceStoreModule';

export default function useVconnexCallback() {
  const STORE_MODULE_NAME = 'smartDevice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, smartDeviceStoreModule);
  }

  const { route } = useRouter();

  const routeQuery = computed(() => route.value.query);
  const { code } = routeQuery.value;
  const countDown = ref(10);
  const validate = ref(-1);

  const startCountdown = () => {
    if (countDown.value > 0) {
      setTimeout(() => {
        countDown.value -= 1;
      }, 1000);
    } else {
      window.location.href = "/smart-device/list";
    }
  };

  const validateResult = () => {
    store.dispatch('smartDevice/syncVConnexAccount', { code }).then(() => {
      validate.value = 1;
      startCountdown();
    }).catch(() => {
      validate.value = 0;
      startCountdown();
    });
  };

  watch(countDown, val => {
    if (val > 0) {
      setTimeout(() => {
        countDown.value -= 1;
      }, 1000);
    } else {
      window.location.href = "/smart-device/list";
    }
  });

  return {
    code,
    validate,
    countDown,
    startCountdown,
    validateResult,
  };
}
