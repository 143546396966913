<template>

  <div class="misc-wrapper bg-white p-0 m-0">

    <div
      v-if="!code"
      class="misc-inner p-2 p-sm-3"
    >
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Dữ liệu không hợp lệ
        </h2>
        <p class="mb-2">
          Dữ liệu không hợp lệ, vui lòng kiểm tra lại
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{ path: '/smart-device/list' }"
        >
          Quay về trang chủ
        </b-button>

        <!-- image -->
        <b-img
          fluid
          src="@/assets/images/pages/error.svg"
          alt="Error page"
        />
      </div>
    </div>
    <div v-else>
      <b-overlay
        no-wrap
        :show="validate === -1"
        rounded="sm"
      />

      <div
        v-if="validate !== - 1"
        class="misc-inner p-2 p-sm-3"
      >
        <div class="w-100 text-center">
          <h2 class="mb-1">
            {{ validate === 1 ? 'Đăng nhập và đồng bộ tài khoản thành công! 🎉' : 'Đăng nhập và đồng bộ tài khoản thất bại 🥹' }}
          </h2>
          <p class="mb-2">
            {{ validate === 1 ? `Bạn đã kết nối thành công vào tài khoản VConnex. Bạn sẽ được chuyển hướng về trang chủ sau ${countDown} s.` : `Kết nối tài khoản VConnex không thành công. Vui lòng kiểm tra và thực hiện lại. Bạn sẽ được chuyển hướng về trang chủ sau ${countDown} s` }}
          </p>
          <b-button
            variant="primary"
            class="mb-1 btn-sm-block"
            to="/"
          >
            Về trang chủ
          </b-button>
          <b-img
            fluid
            :src="require('@/assets/images/pages/payment-success.webp')"
            alt="Not authorized page"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable global-require */
import { BImg, BButton, BOverlay } from 'bootstrap-vue';

// eslint-disable-next-line import/no-cycle
import useVconnexCallback from './useVconnexCallback';

export default {
  components: {
    BImg,
    BButton,
    BOverlay,
  },

  setup() {
    const {
      validateResult, countDown, startCountdown, validate, code,
    } = useVconnexCallback();

    return {
      validateResult, countDown, startCountdown, validate, code,
    };
  },
  created() {
    this.validateResult();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
